export function initDesktopMenu() {
    getAllDropdownArrows().forEach((arrow) => {
        // console.log(arrow);
        arrow.addEventListener('click', (e) => toggleDropdown(e));
        arrow.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                toggleDropdown(e);
                e.preventDefault();
            }
        });
    });
}
function getAllDropdownArrows() {
    return Array.from(document.querySelectorAll('.dropdown-item:has(svg.dropdown-arrow.desktop)'));
}
function toggleDropdown(e) {
    const dropdown = e.currentTarget;
    const dropdownArrow = dropdown.querySelector('svg.dropdown-arrow.desktop');
    dropdownArrow.classList.toggle('dropdown-arrow--open');
    const dropdownMenu = dropdown.nextElementSibling;
    const dropdownItem = dropdown;
    dropdownMenu.classList.toggle('dropdown-menu--open');
    dropdownItem.classList.toggle('dropdown-item--open');
    const child = dropdownMenu.firstElementChild;
    const isOpen = dropdownMenu.classList.contains('dropdown-menu--open');
    if (isOpen) {
        dropdownMenu.style.height = (child === null || child === void 0 ? void 0 : child.getBoundingClientRect().height) + 'px';
        addTabIndex(dropdownMenu);
    }
    else {
        dropdownMenu.style.height = '0px';
        removeTabIndex(dropdownMenu);
    }
}
const removeTabIndex = (menu) => {
    const links = Array.from(menu.querySelectorAll('a'));
    links.forEach((link) => {
        link.setAttribute('tabindex', '-1');
    });
};
const addTabIndex = (menu) => {
    const links = Array.from(menu.querySelectorAll('a'));
    links.forEach((link) => {
        link.setAttribute('tabindex', '0');
    });
};
