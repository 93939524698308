var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// Ensure GSAP plugins are registered
gsap.registerPlugin(ScrollTrigger);
const BORDER_COLOR = getComputedStyle(document.documentElement)
    .getPropertyValue('--color-gray')
    .trim()
    .replace(/['"]+/g, '') || '';
const TRANSLATE_Y_DESKTOP = 'translateY(-26%)';
export function animateParts(scrollY, elements, immediate = false) {
    return __awaiter(this, void 0, void 0, function* () {
        elements.forEach(({ element, properties, scrollDistance }) => {
            const targetElements = gsap.utils.toArray(element);
            targetElements.forEach((targetElement) => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: document.body,
                        start: `${scrollY} top`,
                        end: `+=${scrollDistance}`,
                        markers: false,
                        scrub: immediate ? false : 1 // Use 'false' for immediate animations, or a short duration for quicker response
                    }
                });
                properties.forEach(({ name, fromTo }) => {
                    timeline.fromTo(targetElement, { [name]: fromTo[0] }, { [name]: fromTo[1] });
                });
            });
        });
    });
}
export function initHeaderAnimation() {
    return __awaiter(this, void 0, void 0, function* () {
        const media = window.matchMedia('(min-width: 1023px)');
        if (!media.matches) {
            return;
        }
        // debugScrollingY();
        animateParts(20, [
            {
                element: '.nav-list-items',
                scrollDistance: 50,
                properties: [
                    { name: 'borderColor', fromTo: [BORDER_COLOR, 'transparent'] }
                ]
            },
            {
                element: '.header__nav__brand',
                scrollDistance: 200,
                properties: [
                    { name: 'scale', fromTo: ['1', '0.8'] }
                ]
            }
        ]);
        animateParts(200, [
            {
                element: '.header__nav__brand',
                scrollDistance: 100,
                properties: [
                    { name: 'boxShadow', fromTo: ['0 0 0 0', '0 0 10px 0 rgba(0, 0, 0, 0.1)'] }
                ]
            },
            {
                element: '.header__container',
                scrollDistance: 100,
                properties: [
                    { name: 'boxShadow', fromTo: ['0 0 0 0', '0 0 10px 0 rgba(0, 0, 0, 0.1)'] }
                ]
            },
            {
                element: '.header__nav__brand',
                scrollDistance: 120,
                properties: [
                    { name: 'transform', fromTo: ['translateY(0)', TRANSLATE_Y_DESKTOP] }
                ]
            }
        ]);
    });
}
const debugScrollingY = () => {
    // create an element fixed on the left part of the page that will tell me how much the user has scrolled
    const scrollElement = document.createElement('div');
    scrollElement.style.position = 'fixed';
    scrollElement.style.textAlign = 'center';
    scrollElement.style.left = '0';
    scrollElement.style.top = '0';
    scrollElement.style.width = '50px';
    scrollElement.style.height = '50px';
    scrollElement.style.backgroundColor = 'red';
    document.body.appendChild(scrollElement);
    // console.log how much the user has scrolled
    const updateScrollClass = () => {
        scrollElement.textContent = window.scrollY.toString();
    };
    window.addEventListener('scroll', updateScrollClass);
};
