/**
 * Check if the last element of the main has the class from the params
 * and if it has it, it removes the margin top from the footer.
 * @param {string} selector - The class to check.
 */
export const initCheckLastElement = (selector) => {
    const main = document.querySelector('main');
    const footer = document.querySelector('footer');
    if (!main || !footer)
        return;
    const isLastChildOurSelector = main.lastElementChild == document.querySelector(selector);
    if (isLastChildOurSelector)
        footer.style.marginTop = '0';
};
