/**
 * @author Manuel Sanchez <manuel@webschuppen.com>
 * @version 1.0.0
 *
 * @description
 * This function will create a index navigation with all the h2 in the page
 * It will be added to the element with the class .index
 * Optionally, we added an offsetTop to the scroll to the h2
 *
 * @example
 * initIndexNav();
*/
export const initIndexNav = () => {
    const nav = document.querySelector('.index');
    if (!nav)
        return;
    // Check all the h2 in the page and copy their content to the index
    const ul = document.createElement('ul');
    const h2s = document.querySelectorAll('main h2');
    h2s.forEach((h2, index) => {
        // Add a random id to the h2 that later we will conect with the anchor
        h2.id = 'h2-' + index;
        const li = document.createElement('li');
        li.classList.add('index__item');
        li.innerHTML = `
          <a class="font-weight-regular" href="#${h2.id}">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9111 12.2846L13 12.1562V12V11.5V11.3438L12.9111 11.2154L8.6851 5.11118L9.9123 4.19077L15.5 11.1754V12.815L9.92524 19.3189L8.6851 18.3888L12.9111 12.2846Z" fill="currentColor" stroke="currentColor"/>
              </svg>
              <div class="index__item__text">${h2.textContent}</div>
          </a>`;
        ul.appendChild(li);
    });
    const getMainNavigationHeight = () => {
        let navigationHeight = 0;
        const smallTopLineHeight = 28;
        const headerContainer = document.querySelector('.header__container');
        const headerArch = document.querySelector('.header__arch');
        navigationHeight += smallTopLineHeight;
        navigationHeight += (headerContainer === null || headerContainer === void 0 ? void 0 : headerContainer.getBoundingClientRect().height) || 0;
        navigationHeight += (headerArch === null || headerArch === void 0 ? void 0 : headerArch.getBoundingClientRect().height) || 0;
        return navigationHeight;
    };
    // This part is optional, it will add a scroll to the h2 when the user click on the anchor to 200px counted on the top
    const offsetTop = getMainNavigationHeight();
    ul.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.target; // div.index__item__text
        const targetAnchor = target.parentElement; // a
        if (targetAnchor) {
            const href = targetAnchor.getAttribute('href'); // 'h2-1'
            const h2 = document.querySelector(href); // h2#h2-1
            if (h2) {
                const top = h2.getBoundingClientRect().top - offsetTop;
                window.scrollTo({
                    top: top,
                    behavior: 'smooth'
                });
            }
        }
    });
    nav.appendChild(ul);
};
