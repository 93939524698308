import tippy from 'tippy.js';
/**
 * @author Manuel Sanchez <manuel@webschuppen.com>
 * @version 1.0.0
 *
 * @description
 * Create a tooltip with tippy.js for the elements that have the attribute data-tippy-content
 * To keep accessibility in mind,
 * we will add the title attribute to the element when it is focused
 * and remove it when it is blurred
 * (we do not want to have the tooltip when the element is not focused to avoid duplicated information)
 *
 * @param string selector - The selector that will be used for the elements. Default: [data-tippy-content]
 * @return void
 *
 * @example
 * initTooltipTippy('[data-tippy-content]');
 */
export const initTooltipTippy = (selector) => {
    const elements = document.querySelectorAll(selector) ||
        document.querySelectorAll('[data-tippy-content]');
    if (!elements)
        return;
    elements.forEach((element) => {
        const dataTitle = element.getAttribute('data-tippy-content');
        tippy(element);
        element.addEventListener('focus', function () {
            this.setAttribute('title', dataTitle);
        });
        element.addEventListener('blur', function () {
            this.removeAttribute('title');
        });
    });
};
