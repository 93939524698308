export function initMobileMenu() {
    const menu = document.querySelector('.burger-menu');
    if (!menu)
        return;
    menu.addEventListener('click', () => {
        const navbar = document.querySelector('.mobile__nav');
        menu.classList.toggle('menu-open');
        navbar.classList.toggle('menu-open');
        document.body.classList.toggle('overflow-hidden');
        const isOpen = menu.classList.contains('menu-open');
        if (isOpen) {
            lowerHeroZIndex();
        }
        else {
            restoreHeroZIndex();
        }
    });
    getAllDropdownArrows().forEach((arrow) => {
        arrow.addEventListener('click', (e) => toggleDropdown(e));
    });
}
function getAllDropdownArrows() {
    return Array.from(document.querySelectorAll('.mobile__nav svg.dropdown-arrow'));
}
function toggleDropdown(e) {
    const dropdown = e.currentTarget;
    dropdown.classList.toggle('dropdown-arrow--open');
    const dropdownMenu = dropdown.parentElement.nextElementSibling;
    dropdownMenu.classList.toggle('dropdown-menu--open');
    recalculateDropdownMenuHeight(dropdownMenu);
    const parent = getParentDropDownMenu(dropdown);
    if (!parent)
        return;
    recalculateDropdownMenuHeight(parent);
}
/**
 * Checks if the element has a parent with the class container,
 * that has a parent called dropdown-menu
 * @param element
 */
const getParentDropDownMenu = (element) => {
    // console.log({ element });
    const container = element.parentElement.parentElement.parentElement;
    if (!container || !container.classList.contains('container')) {
        return null;
    }
    const dropdownMenu = container.parentElement;
    if (!dropdownMenu || !dropdownMenu.classList.contains('dropdown-menu')) {
        return null;
    }
    // console.log({ dropdownMenu });
    return dropdownMenu;
};
const recalculateDropdownMenuHeight = (dropdownMenu) => {
    const isOpen = dropdownMenu.classList.contains('dropdown-menu--open');
    const child = dropdownMenu.firstElementChild;
    // console.log({ isOpen });
    if (isOpen) {
        dropdownMenu.style.height = (child === null || child === void 0 ? void 0 : child.getBoundingClientRect().height) + 'px';
    }
    else {
        dropdownMenu.style.height = '0px';
    }
};
let previousHeroZIndex = '0';
let wasPreviouslyScrolled = false;
function lowerHeroZIndex() {
    const header = document.querySelector('header');
    const hero = document.querySelector('img.hero__img');
    if (hero) {
        previousHeroZIndex = hero.style.zIndex;
        hero.style.zIndex = '0';
    }
    wasPreviouslyScrolled = header.classList.contains('scrolled-a-bit');
    header.classList.add('scrolled-a-bit');
}
function restoreHeroZIndex() {
    const hero = document.querySelector('img.hero__img');
    const header = document.querySelector('header');
    setTimeout(() => {
        if (hero) {
            hero.style.zIndex = previousHeroZIndex.toString();
        }
        if (!wasPreviouslyScrolled) {
            header.classList.remove('scrolled-a-bit');
        }
    }, 700);
}
