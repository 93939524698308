import GUI from 'lil-gui';
export class ParamsDebugger {
    static initialize({ show }) {
        const windowWidth = window.innerWidth;
        ParamsDebugger.show = show;
        if (!ParamsDebugger.show || windowWidth < 1024)
            return;
        ParamsDebugger.gui = new GUI();
        const guiElement = document.querySelector('.dg.ac');
        if (guiElement)
            guiElement.style.zIndex = '1000000000000';
        this.addPrimaryColorFolder();
        this.addSecondaryColorFolder();
        this.addOthersColorFolder();
    }
    static addPrimaryColorFolder() {
        const primaryColorFolder = ParamsDebugger.gui.addFolder('Primary Colors');
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimary')
            .name('Color_Primary')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimary = value;
            document.documentElement.style.setProperty('--color-primary', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimaryDark')
            .name('Color_Primary_Dark')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimaryDark = value;
            document.documentElement.style.setProperty('--color-primary-dark', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimaryLight')
            .name('Color_Primary_Light')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimaryLight = value;
            document.documentElement.style.setProperty('--color-primary-light', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimaryLightBg')
            .name('Color_Primary_Light_Bg')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimaryLightBg = value;
            document.documentElement.style.setProperty('--color-primary-light-bg', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimaryLighter')
            .name('Color_Primary_Lighter')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimaryLighter = value;
            document.documentElement.style.setProperty('--color-primary-lighter', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimaryLighterBg')
            .name('Color_Primary_Lighter_Bg')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimaryLighterBg = value;
            document.documentElement.style.setProperty('--color-primary-lighter-bg', value);
        });
        primaryColorFolder
            .addColor(ParamsDebugger.state, 'colorPrimarySemilightBg')
            .name('Color_Primary_Semilight_Bg')
            .onChange((value) => {
            ParamsDebugger.state.colorPrimarySemilightBg = value;
            document.documentElement.style.setProperty('--color-primary-semilight-bg', value);
        });
    }
    static addSecondaryColorFolder() {
        const secondaryColorFolder = ParamsDebugger.gui.addFolder('Secondary Colors');
        secondaryColorFolder
            .addColor(ParamsDebugger.state, 'colorSecondary')
            .name('Color_Secondary')
            .onChange((value) => {
            ParamsDebugger.state.colorSecondary = value;
            document.documentElement.style.setProperty('--color-secondary', value);
        });
        secondaryColorFolder
            .addColor(ParamsDebugger.state, 'colorSecondaryDark')
            .name('Color_Secondary_Dark')
            .onChange((value) => {
            ParamsDebugger.state.colorSecondaryDark = value;
            document.documentElement.style.setProperty('--color-secondary-dark', value);
        });
        secondaryColorFolder
            .addColor(ParamsDebugger.state, 'colorSecondaryLight')
            .name('Color_Secondary_Light')
            .onChange((value) => {
            ParamsDebugger.state.colorSecondaryLight = value;
            document.documentElement.style.setProperty('--color-secondary-light', value);
        });
        secondaryColorFolder
            .addColor(ParamsDebugger.state, 'colorSecondaryLighter')
            .name('Color_Secondary_Lighter')
            .onChange((value) => {
            ParamsDebugger.state.colorSecondaryLighter = value;
            document.documentElement.style.setProperty('--color-secondary-lighter', value);
        });
        secondaryColorFolder
            .addColor(ParamsDebugger.state, 'colorSecondaryLighterBg')
            .name('Color_Secondary_Lighter_Bg')
            .onChange((value) => {
            ParamsDebugger.state.colorSecondaryLighterBg = value;
            document.documentElement.style.setProperty('--color-secondary-lighter-bg', value);
        });
        secondaryColorFolder.close();
    }
    static addOthersColorFolder() {
        const othersColorFolder = ParamsDebugger.gui.addFolder('Other Colors');
        othersColorFolder
            .addColor(ParamsDebugger.state, 'colorMenu')
            .name('Color_Menu')
            .onChange((value) => {
            ParamsDebugger.state.colorMenu = value;
            document.documentElement.style.setProperty('--color-menu', value);
        });
        othersColorFolder
            .addColor(ParamsDebugger.state, 'colorText')
            .name('Color_Text')
            .onChange((value) => {
            ParamsDebugger.state.colorText = value;
            document.documentElement.style.setProperty('--color-text', value);
        });
        othersColorFolder
            .addColor(ParamsDebugger.state, 'colorGray')
            .name('Color_Gray')
            .onChange((value) => {
            ParamsDebugger.state.colorGray = value;
            document.documentElement.style.setProperty('--color-gray', value);
        });
        othersColorFolder
            .addColor(ParamsDebugger.state, 'colorGrayDark')
            .name('Color_Gray_Dark')
            .onChange((value) => {
            ParamsDebugger.state.colorGrayDark = value;
            document.documentElement.style.setProperty('--color-gray-dark', value);
        });
        othersColorFolder.close();
    }
}
ParamsDebugger.state = {
    colorPrimary: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimaryDark: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-dark')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimaryLight: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-light')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimaryLightBg: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-light-bg')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimaryLighter: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-lighter')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimaryLighterBg: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-lighter-bg')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorPrimarySemilightBg: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary-semilight-bg')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorSecondary: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorSecondaryDark: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary-dark')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorSecondaryLight: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary-light')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorSecondaryLighter: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary-lighter')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorSecondaryLighterBg: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary-lighter-bg')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorMenu: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-menu')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorText: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-text')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorGray: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-gray')
        .trim()
        .replace(/['"]+/g, '') || '',
    colorGrayDark: getComputedStyle(document.documentElement)
        .getPropertyValue('--color-gray-dark')
        .trim()
        .replace(/['"]+/g, '') || ''
};
