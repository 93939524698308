export function initSearch() {
    const searchBox = document.querySelector('.tx-indexedsearch-searchbox-sword');
    if (!searchBox)
        return;
    const desktopSearchContainer = document.querySelector('.thin-top-bar__search');
    const desktopSearchInput = document.querySelector('.thin-top-bar__search .tx-indexedsearch-searchbox-sword');
    const desktopSearchButton = document.querySelector('div.thin-top-bar__search > button');
    const mobileSearchButton = document.querySelector('#mobile-search-button');
    const originalSubmitButtonDesktop = document.querySelector('.js-desktop-search .tx-indexedsearch-searchbox-button');
    const originalSubmitButtonMobile = document.querySelector('.js-mobile-search .tx-indexedsearch-searchbox-button');
    let originalSubmitClicked = false;
    if (!desktopSearchContainer ||
        !desktopSearchInput ||
        !mobileSearchButton ||
        !originalSubmitButtonDesktop ||
        !originalSubmitButtonMobile) {
        throw new Error('Search elements not found');
    }
    desktopSearchInput.autocomplete = 'off';
    desktopSearchContainer.addEventListener('click', (event) => {
        desktopSearchInput.focus();
        desktopSearchInput;
        if (!searchBox.classList.contains('tx-indexedsearch-searchbox-sword--visible')) {
            setTimeout(() => {
                searchBox.placeholder = 'Suche';
            }, 200);
        }
        else {
            originalSubmitButtonDesktop.click();
        }
        event.stopPropagation();
    });
    mobileSearchButton.addEventListener('click', (event) => {
        originalSubmitButtonMobile.click();
        event.stopPropagation();
    });
    desktopSearchButton.addEventListener('click', (event) => {
        originalSubmitButtonDesktop.click();
        event.stopPropagation();
    });
    document.addEventListener('click', (event) => {
        if (!searchBox.contains(event.target) && !originalSubmitClicked) {
            searchBox.classList.remove('tx-indexedsearch-searchbox-sword--visible');
            // searchBox.style.width = '0';
            searchBox.style.padding = '';
            searchBox.value = '';
            searchBox.placeholder = '';
        }
        originalSubmitClicked = false;
    });
    originalSubmitButtonDesktop.addEventListener('click', () => {
        originalSubmitClicked = true;
    });
}
