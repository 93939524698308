export const initGoogleMaps = (container) => {
    const iframeWrappers = document.querySelectorAll(container);
    if (!iframeWrappers)
        return;
    iframeWrappers.forEach((iframeWrapper) => {
        let iframe = iframeWrapper.querySelector('iframe');
        const isGmapsCookie = localStorage.getItem("GM-id" /* COOKIES.GOOGLE_MAPS */);
        if (!iframeWrapper && iframe)
            return;
        const dataIframe = iframeWrapper.dataset.iframe;
        const src = dataIframe.split('src="')[1].split('"')[0];
        const cookieBanner = document.querySelector('.cookie-banner');
        const cookieButton = document.querySelector('.cookie-button');
        const html = iframeWrapper.innerHTML;
        const showMapOrCookieMsg = () => {
            const isGmapsCookieNow = localStorage.getItem("GM-id" /* COOKIES.GOOGLE_MAPS */);
            if (isGmapsCookieNow) {
                iframeWrapper.innerHTML = html;
                iframe = iframeWrapper.querySelector('iframe');
                iframe.src = src;
            }
            else {
                iframeWrapper.innerHTML = `
          <p class="open-cookie-wrapper">Bitte, 
            <a style="cursor: pointer;" class="open-cookie">akzeptieren</a> 
            Sie die Google Maps Cookies, um die Karte zu sehen.
          <p>
        `;
                bindOpenCookieBannerEvent(); // Re-bind the event after updating innerHTML
            }
        };
        const openCookieBanner = (e) => {
            e.preventDefault();
            cookieBanner.classList.add('active');
            cookieButton.classList.remove('active');
        };
        const bindOpenCookieBannerEvent = () => {
            const cookieOpenElements = document.querySelectorAll('.open-cookie');
            cookieOpenElements.forEach((element) => {
                element.addEventListener('click', openCookieBanner);
            });
        };
        // Initial logic
        if (isGmapsCookie) {
            iframe.src = src;
        }
        else {
            iframeWrapper.innerHTML = `
        <p class="open-cookie-wrapper">Bitte, 
          <a style="cursor: pointer;" class="open-cookie">akzeptieren</a> 
          Sie die Google Maps Cookies, um die Karte zu sehen.
        <p>
      `;
            bindOpenCookieBannerEvent(); // Bind the event when you first update the innerHTML
        }
        // Listen for updates
        document.addEventListener("cookiesUpdated" /* EVENTS.COOKIES_UPDATED */, showMapOrCookieMsg);
    });
};
