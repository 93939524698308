import { Splide } from '@splidejs/splide';
export const initSlideshows = () => {
    const slideshows = document.querySelectorAll('.slideshow .splide');
    if (!slideshows)
        return;
    slideshows.forEach((item) => {
        new Splide(item, {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            speed: 1000,
            pagination: true,
            gap: '1.0rem',
            focus: 'center',
            breakpoints: {
                768: {
                    perPage: 1
                }
            },
            arrows: true,
            updateOnMove: true
        }).mount();
    });
};
