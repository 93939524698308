var _a, _b, _c, _d, _e, _f;
import FormErrorController from './form/FormErrorController';
export const initCheckErrorInForm = (selector) => {
    const elements = document.querySelectorAll(selector);
    if (elements.length > 0) {
        setTimeout(() => {
            const firstElement = elements[0];
            const { top } = firstElement.getBoundingClientRect();
            window.scrollTo({
                top: top - 300,
                behavior: 'smooth'
            });
        }, 500);
    }
};
export const initRepaintLayoutForm = () => {
    const form = document.querySelector('.contactform');
    if (!form)
        return;
    const firstRow = form.querySelector('.row-one');
    const firstRowChild = firstRow.children[0];
    const secondRow = form.querySelector('.row-two');
    const thirdRow = form.querySelector('.row-three');
    const areRadiosInForm = form.querySelector('input[type="radio"]');
    if (!firstRowChild || !areRadiosInForm || !secondRow || !thirdRow)
        return;
    function fixFormLayout() {
        const windowWidth = window.innerWidth;
        if (windowWidth > 768) {
            firstRowChild.style.overflow = 'auto';
            firstRowChild.style.width = '50vw';
            firstRowChild.style.marginBottom = '20px';
            const firstRowHeight = firstRowChild.clientHeight;
            if (secondRow && thirdRow) {
                const newMargin = (firstRowHeight + 20) + 'px';
                secondRow.style.marginTop = newMargin;
                thirdRow.style.marginTop = newMargin;
            }
        }
        else {
            firstRowChild.style.overflow = 'visible';
            firstRowChild.style.width = 'auto';
            firstRowChild.style.marginBottom = '0';
            if (secondRow && thirdRow) {
                secondRow.style.marginTop = '0';
                thirdRow.style.marginTop = '0';
            }
        }
    }
    fixFormLayout();
    window.addEventListener('resize', fixFormLayout);
};
// Set dynamically the min value for input[type="date"] to the next day
function validateDate(strDate) {
    return !isNaN(new Date(strDate).getTime());
}
function resetTime(objDate) {
    objDate.setHours(0, 0, 0, 0);
}
const disallowedWeekDays = []; // Disallow no week day
const errorMessages = {
    past: 'Der Tag muss in der Zukunft liegen.',
    weekDay: 'An diesem Wochentag nehmen wir keine Spenden an! Bitte einen anderen Wochentag wählen.'
};
// Grab inputs to listen
const forms = document.getElementsByTagName('form');
Array.prototype.forEach.call(forms, function (form) {
    const inputs = form.getElementsByTagName('input');
    Array.prototype.forEach.call(inputs, function (input) {
        if (typeof input !== 'undefined' && input !== null && input.type === 'date') {
            input.addEventListener('input', function () {
                if (validateDate(this.value)) {
                    const validations = {
                        past: false,
                        weekDay: false
                    };
                    const today = new Date();
                    const selectedDate = new Date(this.value);
                    resetTime(selectedDate);
                    resetTime(today);
                    // Check if the choosed date is in the future
                    if (selectedDate > today) {
                        validations.past = true;
                    }
                    // Check if the choosed week day is allowed
                    if (!disallowedWeekDays.includes(selectedDate.getDay())) {
                        validations.weekDay = true;
                    }
                    // If validation fails
                    if (!validations.past || !validations.weekDay) {
                        if (!validations.weekDay) {
                            console.log(errorMessages.weekDay);
                        }
                        if (!validations.past) {
                            console.log(errorMessages.past);
                        }
                        input.value = '';
                        // Trigger Powermail event to insert the element into the DOM for validation error messages
                        this.focus();
                    }
                }
            });
        }
    });
});
// IBAN validator for "Online-Spende-Formular" of Bernostiftung
function validateIBAN(iban) {
    // Remove spaces for validation
    const cleanedIban = iban.replace(/\s+/g, '');
    // Regular expression for basic IBAN validation
    const regex = /^([A-Z]{2}[0-9]{2})([0-9A-Z]{11,30})$/;
    // Check if IBAN is only 'DE'
    if (cleanedIban === 'de' || cleanedIban === 'DE') {
        return 'Bitte geben Sie eine gültige IBAN im korrekten Format ein.';
    }
    // Check if the IBAN matches the regex pattern
    if (!regex.test(cleanedIban)) {
        return 'Bitte geben Sie eine gültige IBAN im korrekten Format ein.';
    }
    // Check if the IBAN length is not more than 22 characters
    if (cleanedIban.length > 22) {
        return 'Die IBAN darf nicht länger als 22 Zeichen sein.';
    }
    // If all checks pass, return an empty string (no error)
    return '';
}
function validateIBANProcess(inputElement) {
    const ibanCheckResult = validateIBAN(inputElement.value);
    if (ibanCheckResult) {
        FormErrorController.addError(inputElement, ibanCheckResult);
    }
    else {
        FormErrorController.removeError(inputElement);
    }
}
const formClasses = 'form online-spende-formular';
const inputClasses = 'text-8 form-control';
const formOsf = document.getElementsByClassName(formClasses);
if (formOsf !== null && formOsf.length === 1) {
    const purpTextInputs = formOsf[0].getElementsByClassName(inputClasses);
    if (purpTextInputs !== null && purpTextInputs.length === 1) {
        purpTextInputs[0].addEventListener('blur', function () {
            validateIBANProcess(purpTextInputs[0]);
        });
        formOsf[0].addEventListener('submit', function () {
            validateIBANProcess(purpTextInputs[0]);
        });
    }
}
// Toggle inputs of the form "Online-Beitrittsformular Niels-Stensen-Schulverein"
const formObnss = document.getElementsByClassName('online-beitrittsformular niels-stensen-schulverein');
if (formObnss !== null && formObnss.length === 1) {
    const purpSelectInputs = formObnss[0].getElementsByClassName('form-check-input');
    if (purpSelectInputs !== null && purpSelectInputs.length === 2) {
        const purpTextInputs = [];
        purpTextInputs[0] = formObnss[0].getElementsByClassName('text-6');
        purpTextInputs[1] = formObnss[0].getElementsByClassName('text-7');
        purpTextInputs[2] = formObnss[0].getElementsByClassName('text-8');
        if (purpTextInputs[0] !== null && purpTextInputs[0].length === 1) {
            (_b = (_a = purpTextInputs[0][0].parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.setAttribute('hidden', 'true');
            (_d = (_c = purpTextInputs[1][0].parentElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.setAttribute('hidden', 'true');
            (_f = (_e = purpTextInputs[2][0].parentElement) === null || _e === void 0 ? void 0 : _e.parentElement) === null || _f === void 0 ? void 0 : _f.setAttribute('hidden', 'true');
        }
        purpSelectInputs[0].addEventListener('change', function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            (_c = (_b = (_a = purpTextInputs[0][0]) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.setAttribute('hidden', 'true');
            (_f = (_e = (_d = purpTextInputs[1][0]) === null || _d === void 0 ? void 0 : _d.parentElement) === null || _e === void 0 ? void 0 : _e.parentElement) === null || _f === void 0 ? void 0 : _f.setAttribute('hidden', 'true');
            (_j = (_h = (_g = purpTextInputs[2][0]) === null || _g === void 0 ? void 0 : _g.parentElement) === null || _h === void 0 ? void 0 : _h.parentElement) === null || _j === void 0 ? void 0 : _j.setAttribute('hidden', 'true');
            purpTextInputs[0][0].value = purpTextInputs[1][0].value = purpTextInputs[2][0].value = '';
            purpTextInputs[0][0].required = purpTextInputs[1][0].required = purpTextInputs[2][0].required = false;
        });
        purpSelectInputs[1].addEventListener('change', function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            (_c = (_b = (_a = purpTextInputs[0][0]) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.removeAttribute('hidden');
            (_f = (_e = (_d = purpTextInputs[1][0]) === null || _d === void 0 ? void 0 : _d.parentElement) === null || _e === void 0 ? void 0 : _e.parentElement) === null || _f === void 0 ? void 0 : _f.removeAttribute('hidden');
            (_j = (_h = (_g = purpTextInputs[2][0]) === null || _g === void 0 ? void 0 : _g.parentElement) === null || _h === void 0 ? void 0 : _h.parentElement) === null || _j === void 0 ? void 0 : _j.removeAttribute('hidden');
            purpTextInputs[0][0].required = purpTextInputs[1][0].required = purpTextInputs[2][0].required = true;
        });
    }
}
