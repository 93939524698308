const DELAY_BETWEEN_IMAGES = 100;
const CLASS_FIGURE_SQUARE = 'figure--square';
const CLASS_FIGURE_THREE_BY_TWO = 'figure--three-by-two';
const CLASS_FIGURE_LANDSCAPE = 'figure--landscape';
const CLASS_FIGURE_PORTRAIT = 'figure--portrait';
const CLASS_FIGURE_UNRESTRICTED = 'figure--unrestricted';
const SVG_ARROW_LEFT = '<svg width="12" height="19" viewBox="0 0 12 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#arrow-left-lighthouse)"><path d="M0 9.5L9.617 19L11.879 16.765L4.525 9.5L11.879 2.235L9.617 0L0 9.5Z" fill="currentColor"/></g><defs><clipPath id="arrow-left-lighthouse"><rect width="19" height="12" fill="white" transform="matrix(0 -1 1 0 0 19)"/></clipPath></defs></svg>';
const SVG_ARROW_RIGHT = '<svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#arrow-right-lighthose)"><path d="M12.5 10L2.883 0.5L0.621 2.735L7.975 10L0.621 17.265L2.883 19.5L12.5 10Z" fill="currentColor"/></g><defs><clipPath id="arrow-right-lighthose"><rect width="19" height="12" fill="white" transform="matrix(0 1 -1 0 12.5 0.5)"/></clipPath></defs></svg>';
/**
 * Initializes a lightbox gallery for images matching a given selector.
 *
 * @author Manuel Sanchez <manuel@webschuppen.com>
 * @version 1.0.1
 * @param {LightboxProps} param0 - An object containing the selector to target images
 * @example
 * initLightbox('.ce-table');
 */
export const initLightbox = ({ selector }) => {
    const lightbox = document.createElement('div');
    const lightboxCloseButton = createButton({
        classes: ['lightbox__close', 'btn-close', 'fade-in'],
        innerHTML: 'X',
        ariaLabel: 'Lightbox Schliessen'
    });
    const lightboxLeftButton = createButton({
        classes: ['lightbox__left', 'fade-in'],
        innerHTML: SVG_ARROW_LEFT,
        ariaLabel: 'Vorheriges Bild'
    });
    const lightboxRightButton = createButton({
        classes: ['lightbox__right', 'fade-in'],
        innerHTML: SVG_ARROW_RIGHT,
        ariaLabel: 'Nächstes Bild'
    });
    let currentImageIndex = 0;
    const trimNamed = selector.replace('.', '');
    const trimNamedWithoutWhiteSpace = trimNamed.replace(/\s/g, '');
    lightbox.id = `${trimNamedWithoutWhiteSpace}-lightbox`;
    lightbox.classList.add('lightbox__wrapper');
    const lightboxImgCaption = document.createElement('div');
    lightboxImgCaption.classList.add('lightbox__img__caption');
    document.body.appendChild(lightbox);
    const images = Array.from(document.querySelectorAll(selector));
    images.forEach((image, index) => {
        image.style.cursor = 'pointer';
        image.addEventListener('click', (e) => {
            var _a;
            const imgElement = e.currentTarget;
            if (((_a = imgElement.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'A') {
                e.preventDefault();
            }
            currentImageIndex = index;
            lightbox.classList.add('active');
            const lightboxUrl = searchParentsForLightboxUrl(image);
            // console.log('This is the lightboxUrl', lightboxUrl);
            // console.log('This is the img preview src', image.src);
            updateLightboxImage(lightboxUrl);
            updateImageCaption();
            document.addEventListener('keydown', handleKeyboardEvent); // Register the keyboard event handler
        });
    });
    /**
     * Handle keyboard events for navigating the lightbox.
     * @param e Keyboard event
     */
    function handleKeyboardEvent(e) {
        if (e.key === 'ArrowLeft' && currentImageIndex > 0) {
            updateLightboxAndIndex(currentImageIndex - 1, images);
        }
        else if (e.key === 'ArrowRight' && currentImageIndex < images.length - 1) {
            updateLightboxAndIndex(currentImageIndex + 1, images);
        }
    }
    /**
     * Apply appropriate class based on aspect ratio
     *
     * @param img - The Image object
     * @param figure - The HTMLElement that wraps the image
     */
    function applyAspectRatioClass(img, figure) {
        const aspectRatio = img.width / img.height;
        console.debug('This is the aspect ratio', aspectRatio);
        let className = '';
        if (aspectRatio >= 0.99 && aspectRatio <= 1.01) {
            className = CLASS_FIGURE_SQUARE;
        }
        else if (aspectRatio >= 1.4 && aspectRatio <= 1.6) {
            className = CLASS_FIGURE_THREE_BY_TWO;
        }
        else if (aspectRatio >= 1.77 && aspectRatio <= 1.79) {
            className = CLASS_FIGURE_LANDSCAPE;
        }
        else if (aspectRatio > 1) {
            className = CLASS_FIGURE_UNRESTRICTED;
        }
        else {
            className = CLASS_FIGURE_PORTRAIT;
        }
        figure.classList.add(className);
    }
    /**
     * Updates the lightbox to display a new image.
     *
     * @param {string} [lightboxUrl] - The URL for the new lightbox image; defaults to currently indexed image
     */
    function updateLightboxImage(lightboxUrl) {
        while (lightbox.firstChild) {
            const child = lightbox.firstChild;
            if (child !== lightboxCloseButton && child !== lightboxLeftButton && child !== lightboxRightButton) {
                lightbox.removeChild(child);
            }
        }
        const figure = document.createElement('figure');
        const img = new Image();
        img.classList.add('fade-in');
        img.src = lightboxUrl || images[currentImageIndex].src;
        img.onload = function () {
            applyAspectRatioClass(img, figure);
            figure.appendChild(img);
            figure.appendChild(lightboxImgCaption);
        };
        figure.style.position = 'relative';
        figure.classList.add('active');
        lightbox.appendChild(figure);
        document.body.classList.add('overflow-hidden');
        figure.appendChild(lightboxCloseButton);
        if (images.length > 1) {
            // Only show left and right buttons if there is more than one image
            figure.appendChild(lightboxLeftButton);
            figure.appendChild(lightboxRightButton);
        }
        updateNavigationButtons();
        setTimeout(() => {
            lightboxCloseButton.classList.add('active');
            lightboxLeftButton.classList.add('active');
            lightboxRightButton.classList.add('active');
            img.classList.add('active');
        }, DELAY_BETWEEN_IMAGES);
        const figcaption = images[currentImageIndex].getAttribute('data-description');
        lightboxImgCaption.innerHTML = figcaption
            ? `<figcaption>${figcaption}</figcaption>`
            : '';
    }
    /**
   * Updates the navigation buttons' disabled state based on the current image index.
   */
    function updateNavigationButtons() {
        // Disable or enable the left navigation button
        if (currentImageIndex === 0)
            lightboxLeftButton.classList.add('disabled');
        else
            lightboxLeftButton.classList.remove('disabled');
        // Disable or enable the right navigation button
        if (currentImageIndex === images.length - 1)
            lightboxRightButton.classList.add('disabled');
        else
            lightboxRightButton.classList.remove('disabled');
    }
    /**
     * Updates the caption of the image in the lightbox.
     */
    function updateImageCaption() {
        const figure = lightbox.querySelector('figure');
        figure.appendChild(lightboxImgCaption);
    }
    /**
     * Update lightbox image and image index
     *
     * @param index - The index of the new image
     * @param images - Array of HTMLImageElement objects
     */
    function updateLightboxAndIndex(index, images) {
        currentImageIndex = index;
        const newLightboxUrl = searchParentsForLightboxUrl(images[currentImageIndex]);
        console.log('this is the new lightbox url', newLightboxUrl);
        updateLightboxImage(newLightboxUrl);
    }
    lightboxLeftButton.addEventListener('click', (e) => {
        if (currentImageIndex > 0) {
            updateLightboxAndIndex(currentImageIndex - 1, images);
        }
    });
    lightboxRightButton.addEventListener('click', (e) => {
        if (currentImageIndex < images.length - 1) {
            updateLightboxAndIndex(currentImageIndex + 1, images);
        }
    });
    lightbox.addEventListener('click', (e) => {
        if (e.target !== e.currentTarget)
            return;
        lightbox.classList.remove('active');
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('keydown', handleKeyboardEvent); // Unregister the keyboard event handler
    });
    lightboxCloseButton.addEventListener('click', () => {
        lightbox.classList.remove('active');
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('keydown', handleKeyboardEvent); // Unregister the keyboard event handler
    });
};
/**
 * Searches the ancestors of an image element for a 'js-clickenlarge' class, which Typo3 provides.
 *
 * @param {HTMLImageElement} imageElement - The image element to start the search from
 * @param {number} levels - The number of parent levels to search, defaults to 3
 * @returns {string|null} - Returns the lightbox URL if found, otherwise the image src
 */
const searchParentsForLightboxUrl = (imageElement, levels = 3) => {
    let parent = imageElement.parentElement;
    for (let i = 0; i < levels; i++) {
        if (parent.classList.contains('js-clickenlarge')) {
            return parent.dataset.lightbox;
        }
        parent = parent.parentElement;
    }
    return imageElement.src;
};
/**
 * Creates a button element with the given classes, innerHTML, and aria-label.
 * @param {CreateButtonProps} param0 - An object containing the classes, innerHTML, and aria-label
 * @returns {HTMLButtonElement} - The button element
 * @example
 * const btn = createButton({
 *  classes: ['btn', 'btn-primary'],
 *  innerHTML: 'X',
 *  ariaLabel: 'Modal Schliessen'
 * });
 */
function createButton({ classes, innerHTML, ariaLabel }) {
    const btn = document.createElement('button');
    btn.classList.add(...classes);
    btn.innerHTML = innerHTML;
    btn.setAttribute('aria-label', ariaLabel);
    return btn;
}
