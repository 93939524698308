export default function initOnlineSpendeFormular() {
    var _a, _b;
    // Toggle an input of the form "Online Spende Formular"
    const formEle = document.getElementsByClassName('online-spende-formular');
    if (formEle !== null && formEle.length === 1) {
        const purpSelectInputs = formEle[0].getElementsByClassName('singleselect-3');
        if (purpSelectInputs !== null && purpSelectInputs.length === 1) {
            const purpTextInputs = formEle[0].getElementsByClassName('text-10');
            if (purpTextInputs !== null && purpTextInputs.length === 1) {
                if (purpSelectInputs[0].value.toLowerCase() !== 'anderer') {
                    (_b = (_a = purpTextInputs[0].parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.setAttribute('hidden', 'true');
                }
            }
            purpSelectInputs[0].addEventListener('change', function () {
                var _a, _b, _c, _d, _e, _f;
                if (this.value.toLowerCase() === 'anderer') {
                    (_c = (_b = (_a = purpTextInputs[0]) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.removeAttribute('hidden');
                }
                else {
                    (_f = (_e = (_d = purpTextInputs[0]) === null || _d === void 0 ? void 0 : _d.parentElement) === null || _e === void 0 ? void 0 : _e.parentElement) === null || _f === void 0 ? void 0 : _f.setAttribute('hidden', 'true');
                    purpTextInputs[0].value = '';
                }
            });
        }
        const dateInputs = formEle[0].getElementsByTagName('input');
        Array.from(dateInputs).forEach((inputElement) => {
            inputElement.addEventListener('click', function () {
                this.showPicker();
            });
        });
    }
}
