import { Splide } from '@splidejs/splide';
export const initHeroSlider = () => {
    const heroSlider = document.querySelector('#hero-slider');
    // console.log(heroSlider, Splide)
    if (!heroSlider)
        return;
    new Splide(heroSlider, {
        type: 'loop',
        autoplay: true,
        speed: 4000
    }).mount();
};
